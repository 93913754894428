/* Libraries */
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
/* Material UI */
import Modal from '@material-ui/core/Modal';
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { useTheme } from '@material-ui/core/styles';
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
/* Components */
import TabPanel from 'components/TabPanel';
import { useToast } from 'Context/ToastContext';
import TargetTab from './component/TargetTab';
import { useModal } from 'Context/ModalContext';
import LightningAlert from './component/AlertsForm/LightningAlert';
import RainAlert from './component/AlertsForm/RainAlert';
import Releases from './component/AlertsForm/Releases';
import ConfirmationModal from './component/ConfirmationModal';
import { FaCloudShowersHeavy, FaSignOutAlt, FaBolt } from 'react-icons/fa'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
/* CSS */
import fontStyles from "styles/fonts-jss";
import styles from "./alerts_panel-jss";
import styled from 'styled-components';
/* Services */
import { postLightningAlert, postLightningRelease, postRainAlert } from "../../../../services/mapsPortal/alerts"


const Body = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`
const LeftSide = styled.div`
  background-color: white;
  border-radius: 13px 0 0 13px;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 45%
  };
  @media (min-width: 1024px) {
    width: 40%
  };
  @media (min-width: 1280px) {
    width: 40%
  };
  @media (min-width: 1366px) {
    width: 40%
  };
  @media (min-width: 1440px) {
    width: 40%
  };
`
const LeftSide_TitleDiv = styled.div`
  height: 13%;
  width: 100%;
  background-color: #1B1B1B;
  border-radius: 13px 0 0 0;
  text-align: center;
  justify-content: center;
  display: flex;
`
const LeftSide_ContentDiv = styled.div`
  height: 78%;
  width: 100%;
  background-color: white;
  border-radius: 0 0 0 13px;
  position: relative;
  box-shadow: 4px 0px 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`

const LeftSide_FooterDiv = styled.div`
  height: 9%;
  width: 100%;
  background-color: white;
  border-radius: 0 0 0 13px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  position: relative;
`

const RightSide = styled.div`
  background-color: white;
  border-radius: 0 13px 13px 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  @media (min-width: 768px) {
    width: 55%
  };
  @media (min-width: 1024px) {
    width: 60%
  };
  @media (min-width: 1280px) {
    width: 60%
  };
  @media (min-width: 1366px) {
    width: 60%
  };
  @media (min-width: 1440px) {
    width: 60%
  };
`

const RightSide_TabsDiv = styled.div`
  height: 13%;
  width: 100%;
  background-color: white;
  border-radius: 0 13px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
  position: relative;
`
const RightSide_ContentDiv = styled.div`
  height: 78%;
  width: 100%;
  background-color: white;
  border-radius: 0 0 0 13px;
  overflow-y: auto;
`

const RightSide_FooterDiv = styled.div`
  height: 9%;
  width: 100%;
  background-color: white;
  border-radius: 0 0 13px 0;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  `


const useStyles = makeStyles({
  lightningAlert: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#B067E9",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#B067E9'
    }
  },
  rainAlert: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#046DCA",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#046DCA'
    }
  },
  meteorologicalAlert: {
    "& .MuiTabs-indicator": {
      backgroundColor: "black",
    },
    "& .MuiTab-root.Mui-selected": {
      color: 'black'
    }
  },
  releases: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#27B624",
    },
    "& .MuiTab-root.Mui-selected": {
      color: '#27B624'
    }
  },
  switch_primary: {
    "&.Mui-checked": {
      color: "#1B1B1B",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1B1B1B",
    },
  },
})


const AlertsPanel = (props) => {
  const theme = useTheme();
  const fonts = fontStyles();
  const openToast = useToast();
  const tabStyle = useStyles();
  const { classes } = props;
  const { open, plans, closeModal } = useModal();
  const [alertsData, setAlertsData] = useState([]);
  const [rightSideTabs, setRightSideTabs] = useState(props.tab);
  const [allSelected, setAllSelected] = useState(false);
  const [test, setTest] = useState(false);
  const [checkboxSelected, setCheckboxSelected] = useState();
  const [statusTargetSelected, setStatusTargetSelected] = useState([]);
  const [reloadStatusTargetSelected, setReloadStatusTargetSelected] = useState(false);
  const [reloadSwitchSelected, setReloadSwitchSelected] = useState(false);
  const [reloadPostData, setReloadPostData] = useState(false);
  const [reloadSelected, setReloadSelected] = useState(false);
  const [reloadSelectAll, setReloadSelectAll] = useState(false);
  const [reloadTargets, setReloadTargets] = useState(false);
  const [confirmTargetsList, setConfirmTargetsList] = useState();
  const [selected, setSelected] = useState("");
  const [reloadErrorMessage, setReloadErrorMessage] = useState(false);
  const [reloadLoading, setReloadLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [loadingLeftSide, setLoadingLeftSide] = useState([false]);
  const [reloadLeftSide, setReloadLeftSide] = useState(false);
  const [lightningAlertErrors, setLightningAlertErrors] = useState([]);
  const [releaseAlertErrors, setReleaseAlertErrors] = useState([]);
  const [rainAlertErrors, setRainAlertErrors] = useState([]);
  const [servicesLightningAlert, setServicesLightningAlert] = useState();


  const handleChangeRightTabs = (event, newValue) => {
    setRightSideTabs(newValue);
    setReloadTargets(true);
  };

  const handleChangeIndexRightTabs = (index) => {
    setRightSideTabs(index);
    setReloadTargets(true);
  };
  const handleClose = () => {
    closeModal();
  }

  const postAlert = async () => {
    if (rightSideTabs === 0) {
      let aux = false;
      if(lightningAlertErrors && lightningAlertErrors.length > 0){
        let errorIndex = lightningAlertErrors.map((item, index) => item.level != null || item.risk != null || item.contact != null || item.duration != null || item.tool != null?  index : -1)
        for(let i = 0; i < errorIndex.length; i++){
          if (errorIndex[i] != -1) {
            aux = true;
            openToast("error", lightningAlertErrors[errorIndex[i]].level != null? lightningAlertErrors[errorIndex[i]].level : lightningAlertErrors[errorIndex[i]].risk != null? lightningAlertErrors[errorIndex[i]].risk : lightningAlertErrors[errorIndex[i]].tool != null? lightningAlertErrors[errorIndex[i]].tool : lightningAlertErrors[errorIndex[i]].contact != null? lightningAlertErrors[errorIndex[i]].contact : null);
          }
        }
      }
      if(!aux){
        if (alertsData && alertsData.length === 0) {
          openToast("warning", "Nenhum alvo selecionado!");
        }
        else {
          if ((statusTargetSelected && statusTargetSelected.length > 0)) {
            setOpenConfirmationModal(true);
          }
          else {
            openToast("warning", "Enviando Alerta Raio...!");
            let targetNamesNormalErrors = [];
            let targetNamesContactErrors = [];

            for (let i = 0; i < alertsData.length; i++) {
              let targetName = alertsData[i].nome;
              if (alertsData[i]) {
                if (alertsData[i].nivel != "") {
                  delete alertsData[i].nome;
                  alertsData[i].teste = test;
                  alertsData[i]?.observacao?.length > 0? alertsData[i].observacao = alertsData[i].observacao.trim() : alertsData[i].observacao = null;

                  await postLightningAlert(alertsData[i]).then((response) => {
                    if(response.status != 201){
                      targetNamesNormalErrors.push(targetName)
                    }
                  }).catch((error) => {
                    if(error.response.data[0] === "Alvo sem contatos para o envio de alertas."){
                      targetNamesContactErrors.push(targetName);
                    }
                    else{
                      targetNamesNormalErrors.push(targetName);
                    }
                  })
                }
              }
            }
            if (!targetNamesNormalErrors.length && !targetNamesContactErrors.length) {
              openToast("success", "Alerta Raio enviado com sucesso!");
              setReloadTargets(true);
            }
            else {
              if(targetNamesContactErrors.length > 0){
                openToast("error", `Adicione usuário(s) ao(s) alvo(s) ${targetNamesContactErrors} antes de continuar!`);
              }
              if(targetNamesNormalErrors.length > 0){
                openToast("error", `Problema ao enviar Alerta Raio ao(s) alvo(s) ${targetNamesNormalErrors}`);
              }
              setReloadTargets(true);
            }
          }
        }
      }
    }
    else if (rightSideTabs === 1) {
      let aux = false;
      if(releaseAlertErrors && releaseAlertErrors.length > 0){
        let errorIndex = releaseAlertErrors.map((item, index) => item.level != null || item.risk != null || item.contact != null?  index : -1)

        for(let i = 0; i < errorIndex.length; i++){
          if (errorIndex[i] != -1) {
            aux = true;
            openToast("error", releaseAlertErrors[errorIndex[i]].level != null? releaseAlertErrors[errorIndex[i]].level : releaseAlertErrors[errorIndex[i]].risk != null? releaseAlertErrors[errorIndex[i]].risk : releaseAlertErrors[errorIndex[i]].contact != null? releaseAlertErrors[errorIndex[i]].contact : null);
          }
        }
      }
      if(!aux){
        if (alertsData && alertsData.length === 0) {
          openToast("warning", "Nenhum alvo selecionado!");
        }
        else {
          openToast("warning", "Liberando alvo...!");
          let targetNamesNormalErrors = [];
          let targetNamesContactErrors = [];

          for (let i = 0; i < alertsData.length; i++) {
            let targetName = alertsData[i].nome;
            alertsData[i].datahora_criacao = null;

            if (alertsData[i]) {
              if (alertsData[i].nivel != "" || alertsData[i].nivel != null) {
                delete alertsData[i].nome;
                alertsData[i].teste = test;
                alertsData[i]?.observacao?.length > 0? alertsData[i].observacao = alertsData[i].observacao.trim() : alertsData[i].observacao = null;

                await postLightningRelease(alertsData[i]).then((response) => {
                  if(response.status != 201){
                    targetNamesNormalErrors.push(targetName);
                  }
                }).catch((error) => {
                  if(error.response.data[0] === "Alvo sem contatos para o envio de alertas."){
                    targetNamesContactErrors.push(targetName);
                  }
                  else{
                    targetNamesNormalErrors.push(targetName);
                  }
                })
              }
            }
          }
          if (!targetNamesNormalErrors.length && !targetNamesContactErrors.length) {
            openToast("success", "Liberação realizada com sucesso!");
            setReloadTargets(true);
          }
          else {
            if(targetNamesContactErrors.length > 0){
              openToast("error", `Adicione usuário(s) ao(s) alvo(s) ${targetNamesContactErrors} antes de continuar!`);
            }
            if(targetNamesNormalErrors.length > 0){
              openToast("error", `Problema ao liberar o(s) alvo(s) ${targetNamesNormalErrors}`);
            }
            setReloadTargets(true);
          }
        }
      }
    }
    else if (rightSideTabs === 2) {
      let aux = false;
      if(rainAlertErrors && rainAlertErrors.length > 0){
        let errorIndex = rainAlertErrors.map((item, index) => item.acumulatedInit != null || item.acumulatedFinal != null || item.intensity != null || item.contact != null || item.duration != null? index : -1)

        for(let i = 0; i < errorIndex.length; i++){
          if (errorIndex[i] != -1) {
            aux = true;
            openToast("error", rainAlertErrors[errorIndex[i]].acumulatedInit != null? rainAlertErrors[errorIndex[i]].acumulatedInit : rainAlertErrors[errorIndex[i]].acumulatedFinal != null? rainAlertErrors[errorIndex[i]].acumulatedFinal : rainAlertErrors[errorIndex[i]].intensity != null? rainAlertErrors[errorIndex[i]].intensity : rainAlertErrors[errorIndex[i]].contact != null? rainAlertErrors[errorIndex[i]].contact : rainAlertErrors[errorIndex[i]].duration != null? rainAlertErrors[errorIndex[i]].duration : null);
          }
        }
      }
      if(!aux){
        if (alertsData && alertsData.length === 0) {
          openToast("warning", "Nenhum alvo selecionado!");
        }
        else {
          if ((statusTargetSelected && statusTargetSelected.length > 0)) {
            setOpenConfirmationModal(true);
          }
          else {
            openToast("warning", "Enviando Aviso de Chuva...!");
            let targetNamesNormalErrors = [];
            let targetNamesContactErrors = [];

            for (let i = 0; i < alertsData.length; i++) {
              let targetName = alertsData[i].nome;
              if (alertsData[i]) {
                if (alertsData[i].contato != "" || alertsData[i].contato != null) {
                  delete alertsData[i].nome;
                  alertsData[i].teste = test;
                  alertsData[i]?.observacao?.length > 0? alertsData[i].observacao = alertsData[i].observacao.trim() : alertsData[i].observacao = null;
                  alertsData[i].acumulado = alertsData[i]?.acumuladoInicial?.trim() + ' - ' + alertsData[i]?.acumuladoFinal?.trim() + ' mm';
                  
                  await postRainAlert(alertsData[i]).then((response) => {
                    if(response.status != 201){
                      targetNamesNormalErrors.push(targetName)
                    }
                  }).catch((error) => {
                    if(error.response.data[0] === "Alvo sem contatos para o envio de alertas."){
                      targetNamesContactErrors.push(targetName);
                    }
                    else{
                      targetNamesNormalErrors.push(targetName);
                    }
                  })
                }
              }
            }
            if (!targetNamesNormalErrors.length && !targetNamesContactErrors.length) {
              openToast("success", "Aviso de Chuva enviado com sucesso!");
              setReloadTargets(true);
            }
            else {
              if(targetNamesContactErrors.length > 0){
                openToast("error", `Adicione usuário(s) ao(s) alvo(s) ${targetNamesContactErrors} antes de continuar!`);
              }
              if(targetNamesNormalErrors.length > 0){
                openToast("error", `Problema ao enviar Aviso de Chuva ao(s) alvo(s) ${targetNamesNormalErrors}`);
              }
              setReloadTargets(true);
            }
          }
        }
      }
    }
  }

  const toggleAllSelected = () => {
    setAllSelected(!allSelected);
  }


  useEffect(() => {
    setReloadPostData(false);
  }, [reloadPostData])

  useEffect(() => {
    setReloadSwitchSelected(false);
  }, [reloadSwitchSelected])

  useEffect(() => {
    setReloadStatusTargetSelected(false);
  }, [reloadStatusTargetSelected])

  useEffect(() => {
    setReloadLeftSide(false);
  }, [reloadLeftSide])

  useEffect(() => {
    if (checkboxSelected && checkboxSelected.length > 0) {
      for (let i = 0; i < checkboxSelected.length; i++) {
        if (checkboxSelected[i] === false) {
          setAllSelected(false);
        }
      }
    }
    setReloadSelectAll(true);
    setReloadSelected(false);
  }, [reloadSelected])

  useEffect(() => {
    setReloadErrorMessage(false);
  }, [reloadErrorMessage])

  useEffect(() => {
    setReloadLoading(false);
  }, [reloadLoading])

  useEffect(() => {
    setAllSelected(false);
    setAlertsData([]);
  }, [rightSideTabs])


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Body>
        <LeftSide>
          <LeftSide_TitleDiv>
            <AppBar position="static" color="transparent" style={{ alignSelf: "center" }}>
              <span style={fonts.panelTitle}>MONITORAMENTO</span>
            </AppBar>
          </LeftSide_TitleDiv>
          <LeftSide_ContentDiv>
            {loadingLeftSide.length != 0 &&
              <CircularProgress style={{ marginLeft: "calc(45%)", marginTop: "calc(20%)" }} size={50} thickness={1} color="primary" />
            }
            <TargetTab tab={rightSideTabs} loadingLeftSide={loadingLeftSide} setLoadingLeftSide={setLoadingLeftSide} setReloadLeftSide={setReloadLeftSide} />
          </LeftSide_ContentDiv>
          <LeftSide_FooterDiv>
            <Button onClick={() => handleClose()} style={{ marginLeft: "25px" }}>
              <ArrowBackIcon style={{ width: "30px", height: "30px ", color: "#1B1B1B" }} />
            </Button>
          </LeftSide_FooterDiv>
        </LeftSide>

        <RightSide>
          <RightSide_TabsDiv>
            <Tabs
              value={rightSideTabs}
              onChange={handleChangeRightTabs}
              className={rightSideTabs === 0 ? tabStyle.lightningAlert : rightSideTabs === 1 ? tabStyle.releases : rightSideTabs === 2 ? tabStyle.rainAlert : tabStyle.meteorologicalAlert}
              variant="fullWidth"
              aria-label="full width tabs example"
              style={{ height: "100%", display: "flex", alignItems: "center" }}
              centered={true}
            >
              <Tab icon={<FaBolt style={{ width: "22px", height: "22px" }} />} label="Alerta Raio" />
              <Tab icon={<FaSignOutAlt style={{ width: "22px", height: "22px" }} />} label="Liberações" />
              <Tab icon={<FaCloudShowersHeavy style={{ width: "22px", height: "22px" }} />} label="Aviso de Chuva" />
            </Tabs>
          </RightSide_TabsDiv>

          <RightSide_ContentDiv>
            {loadingLeftSide.length != 0 ?
              <CircularProgress style={{ marginLeft: "calc(45%)", marginTop: "calc(20%)" }} size={50} thickness={1} color="primary" /> :

              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={rightSideTabs}
                onChangeIndex={handleChangeIndexRightTabs}
              >
                <TabPanel value={rightSideTabs} index={0} dir={theme.direction}>
                  <LightningAlert alertsData={alertsData} setAlertsData={setAlertsData} setReloadPostData={setReloadPostData} setSelected={setSelected} setReloadSelected={setReloadSelected} confirmTargetsList={confirmTargetsList} setReloadSwitchSelected={setReloadSwitchSelected} allSelected={allSelected} setAllSelected={setAllSelected} setReloadErrorMessage={setReloadErrorMessage} statusTargetSelected={statusTargetSelected} setStatusTargetSelected={setStatusTargetSelected} setReloadStatusTargetSelected={setReloadStatusTargetSelected} reloadTargets={reloadTargets} setReloadTargets={setReloadTargets} loadingLeftSide={loadingLeftSide} lightningAlertErrors={lightningAlertErrors} setLightningAlertErrors={setLightningAlertErrors} setServicesLightningAlert={setServicesLightningAlert}/>
                </TabPanel>
                <TabPanel value={rightSideTabs} index={1} dir={theme.direction}>
                  <Releases alertsData={alertsData} setAlertsData={setAlertsData} setReloadPostData={setReloadPostData} setSelected={setSelected} setReloadSelected={setReloadSelected} confirmTargetsList={confirmTargetsList} setReloadSwitchSelected={setReloadSwitchSelected} allSelected={allSelected} setAllSelected={setAllSelected} setReloadErrorMessage={setReloadErrorMessage} reloadTargets={reloadTargets} setReloadTargets={setReloadTargets} loadingLeftSide={loadingLeftSide} setReleaseAlertErrors={setReleaseAlertErrors} releaseAlertErrors={releaseAlertErrors} servicesLightningAlert={servicesLightningAlert} />
                </TabPanel>
                <TabPanel value={rightSideTabs} index={2} dir={theme.direction}>
                  <RainAlert alertsData={alertsData} setAlertsData={setAlertsData} setReloadPostData={setReloadPostData} setSelected={setSelected} setReloadSelected={setReloadSelected} confirmTargetsList={confirmTargetsList} setReloadSwitchSelected={setReloadSwitchSelected} allSelected={allSelected} setAllSelected={setAllSelected} setReloadErrorMessage={setReloadErrorMessage} statusTargetSelected={statusTargetSelected} setStatusTargetSelected={setStatusTargetSelected} setReloadStatusTargetSelected={setReloadStatusTargetSelected} loadingLeftSide={loadingLeftSide} rainAlertErrors={rainAlertErrors} setRainAlertErrors={setRainAlertErrors}  reloadTargets={reloadTargets} setReloadTargets={setReloadTargets}/>
                </TabPanel>
              </SwipeableViews>
            }
          </RightSide_ContentDiv>

          <RightSide_FooterDiv>
            <div className={classes.allSelectedDiv} >
              <Switch
                checked={allSelected}
                onChange={() => toggleAllSelected()}
                value={allSelected}
                size="small"
                classes={{
                  colorPrimary: tabStyle.switch_primary
                }}
                color={"primary"} />

                <Typography variant="subtitle1" style={fonts.planName}>
                  Selecionar todos
                </Typography>
            </div>

            <div className={classes.testDiv} >
              <Switch
                checked={test}
                onChange={() => setTest(!test)}
                value={test}
                size="small"
                classes={{
                  colorPrimary: tabStyle.switch_primary
                }}
                color={"primary"} />

                <Typography variant="subtitle1" style={fonts.planName}>
                  Envio de teste
                </Typography>
            </div>

            <div className={classes.divButton}>
              <Button style={fonts.buttonDefault} variant="contained" className={rightSideTabs === 0 ? classes.buttonLightning : rightSideTabs === 1 ? classes.buttonRelease : classes.buttonRain} onClick={() => postAlert()}>
                Enviar selecionados
                <div className={classes.iconContainer}>
                  {rightSideTabs === 0 ? <FaBolt style={{ width: "16px", height: "16px", color: "#B067E9" }} /> : rightSideTabs === 1 ? <FaSignOutAlt style={{ width: "16px", height: "16px", color: "#27B624" }} /> : <FaCloudShowersHeavy style={{ width: "16px", height: "16px", color: "#046DCA" }} />}
                </div>
              </Button>
            </div>
          </RightSide_FooterDiv>
        </RightSide>

        {openConfirmationModal && <ConfirmationModal rightSideTabs={rightSideTabs} statusTargetSelected={statusTargetSelected} openConfirmationModal={openConfirmationModal} setOpenConfirmationModal={setOpenConfirmationModal} alertsData={alertsData} setAlertsData={setAlertsData} setReloadTargets={setReloadTargets} />}
      </Body>
    </Modal>
  )
}


AlertsPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  tab: PropTypes.object.isRequired
};

export default withStyles(styles)(AlertsPanel);
/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
/* CSS */
import styles from "./rain-alerts_forms-jss";
import fontStyles from "styles/fonts-jss";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
    lightningAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#B067E9",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#B067E9'
      }
    },
    rainAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#046DCA",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#046DCA'
      }
    },
    meteorologicalAlert: {
      "& .MuiTabs-indicator": {
        backgroundColor: "black",
      },
      "& .MuiTab-root.Mui-selected": {
        color: 'black'
      }
    },
    releases: {
      "& .MuiTabs-indicator": {
        backgroundColor: "#27B624",
      },
      "& .MuiTab-root.Mui-selected": {
        color: '#27B624'
      }
    },
    switch_primary: {
      "&.Mui-checked": {
        color: "#1B1B1B",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#1B1B1B",
      },
    },
    divider: {
      background: "#A5A5A5",
      width: "100%"
    },
  })
  

const AlertsElementRain = (props) => {
    const {classes, rightSideTabs, indexPage, company, value, setReloadRightSide, setAllValues, allValues} = props;
    const fonts = fontStyles();
    const theme = useTheme();
    const dividerStyle = useStyles();
    var gridClasses = [
        classes.gridColumnsComponent,
        classes.gridMarginLeft,
    ]
    const [companyValues, setCompanyValues] = useState(null);
    const [reload, setReload] = useState(false);
    const [invitePrincipal, setInvitePrincipal] = useState(false);
    const [postSwitchs, setPostSwitchs] = useState([]);
    const defaultProps = {
      options: ["T1", "T2", "T3", "T4"],
    };

    const tools = ["Descargas", "Radar", "Satélite", "TSC"]

    const checkVerify = (valueCheck)=>{
      let aux = ""
      companyValues.filter((value) => {
        value.includes(valueCheck)? 
          aux = value.split("_")[3] 
        : 
          aux = ""
      })
      return(aux)
    };

    useEffect(() => {
      setReload(false);
    }, [reload])

    const initialSets = () => {
      let aux = []
      aux = {nome: value.nome, nivel: value.nivel, duracao: value.dur_est, risco: value.risco, id_empresa_id: value.id_empresa_id, contato: value.contato, observacao: value.observacao, ferramenta: value.ferramenta?.includes(",")? value.ferramenta?.split(",")  : value.ferramenta==""? [] : [value.ferramenta], datahora_liberacao: value.datahora_liberacao, liberado: value.liberado, id_alerta: value.id, meteorologista: value.meteorologista, errata: value.errata, campos_modificados: [], intensidade: value.intensidade, acumulado: value.acumulado, id_og: value.id_og}
      setCompanyValues(aux)
    };

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
      setAllValues({nivel: companyValues.nivel, observacao: companyValues.observacao, ferramenta: companyValues.ferramenta, duracao: companyValues.duracao, risco: companyValues.risco, liberado: companyValues.liberado!=null&&companyValues.liberado!=""? companyValues.liberado : false, contato: companyValues.contato, datahora_liberacao: companyValues.datahora_liberacao, meteorologista: companyValues.meteorologista, id_alerta: companyValues.id_alerta, errata: companyValues.errata, campos_modificados: companyValues.campos_modificados, intensidade: companyValues.intensidade, acumulado: companyValues.acumulado, id_og: companyValues.id_og});
      setChecked(!checked);
      setReloadRightSide(true);
    };

    const modifyAllValues = () => {
      setAllValues(companyValues);
      setReloadRightSide(true);
    };
    
    const getPostSwitchs = (element) => {
      const finder = postSwitchs.find((filteredButton) => filteredButton == element);
      let aux = false
      if(finder === undefined || finder == ""){
        aux = false
      }else{
        aux = true
      }
      return aux
    };
    
    useEffect(() => {
      initialSets();
    }, [])

    const addModifiedValue = (input, newValue) =>{
      let aux = companyValues

      if(aux.campos_modificados.length > 0){
        if(!aux.campos_modificados.includes(input)){
          aux.campos_modificados.push(input)
        }
      }else{
        aux.campos_modificados.push(input)
      }

      if(input=="acumuladoInicial"){
        aux.acumuladoInicial = newValue
      }
      if(input=="acumuladoFinal"){
        aux.acumuladoFinal = newValue
      }
      if(input=="intensidade"){
        aux.intensidade = newValue
      }
      if(input=="contato"){
        aux.contato = newValue
      }
      if(input=="duracao"){
        aux.duracao = newValue
      }
      if(input=="observacao"){
        aux.observacao = newValue
      }
      setCompanyValues(aux);
      setReload(true);
      modifyAllValues();
    };

    if(companyValues!=null) {
      
    return (
      <Grid container spacing={1} direction="column" alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: "5px", padding:'10px' }}>
        <Grid item xs={12} className={classes.titleContainer}>
          <Switch
            checked={checked}
            onChange={handleChange}
            size="small"
            color={"primary"} />
            <div style={{ marginLeft: "15px" }}>
              <Typography variant="subtitle1">
                {value.nome}
              </Typography>
            </div>
      </Grid>

      <Grid container className={classes.gridContainer}>
        <div className={classes.acumulatedContainer}>
          <span style={fonts.formsFieldHeight6}>Acumulado</span>
          <div className={classes.acumulatedFieldsContainer}>
            <TextField
               id="acumulatedInitial"
               multiline
               shrink={true}
               value={companyValues?.acumuladoInitial}
               onChange={(_, newValue) => addModifiedValue("acumuladoInicial", newValue)}
               className={classes.acumulatedField}
               variant="outlined"
               color="primary"
               size="small"
               InputLabelProps={{ shrink: true }}
               inputProps={{ style: fonts.formsFieldHeight2, maxLength: 3 }}
            />
            <span style={fonts.formsFieldHeight2}>-</span>
            <TextField
              id="acumulatedFinal"
              multiline
              shrink={true}
              value={companyValues?.acumuladoFinal}
              onChange={(_, newValue) => addModifiedValue("acumuladoFinal", newValue)}
              className={classes.acumulatedField}
              variant="outlined"
              color="primary"
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: fonts.formsFieldHeight2, maxLength: 3 }}
            />
            <span style={fonts.formsFieldHeight2}>mm</span>
          </div>
        </div>

        <InputMask
          mask="99:99"
          value={companyValues?.duracao}
          onChange={(e) => addModifiedValue("duracao", e.target.value)}
          disabled={false}
          maskChar=" "
        >
          {() =>
            <TextField
              id="duration"
              className={classes.durationField}
              margin="normal"
              variant="outlined"
              label="Duração"
              inputProps={{ style: fonts.formsFieldHeight1 }}
              InputLabelProps={{ style: fonts.formsLabel, shrink: true }}
            />}
        </InputMask>

        <Autocomplete
          id="contacts"
          freeSolo
          value={companyValues?.contato}
          inputValue={companyValues?.contato}
          disableClearable
          onInputChange={(_, newValue) => addModifiedValue("contato", newValue)}
          onChange={(_, newValue) => addModifiedValue("contato", newValue)}
          className={classes.contactsField}
          style={fonts.formsFieldHeight1}
          size="small"
          options={["Não foi possível o contato telefônico.", "Não foi realizado o contato", "Não há contato telefônico das xx:xx até às xx:xx"]}
          renderInput={(params) =>
            <TextField
              {...params}
              label="Contato"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onKeyDown={(event) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation();
                }
              }}
            />}
          />
        </Grid>

        <Grid container className={classes.gridContainer} style={{marginTop: '10px'}}>
          <Autocomplete
            id="intensity"
            freeSolo={false}
            value={companyValues?.intensidade}
            inputValue={companyValues?.intensidade}
            onChange={(_, newValue) => addModifiedValue("intensidade", newValue)}
            disableClearable
            className={classes.intensityField}
            style={fonts.formsFieldHeight1}
            size="small"
            options={["Fraca", "Fraca a moderada", "Moderada", "Moderada a forte", "Forte", "Muito forte", "Extrema"]}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Intensidade"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                }}
              />}
          />

          <TextField
            id="comments"
            label="Observações"
            multiline
            shrink={true}
            value={companyValues?.observacao}
            onChange={(e) => addModifiedValue("observacao", e.target.value)}
            className={classes.commentsField}
            variant="outlined"
            color="primary"
            size="small"
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: fonts.formsFieldHeight2, maxLength: 50 }}
          />
        </Grid>

      {/*<Grid container className={classes.commentsContainer}>
        {caractersRemaning && <p className={classes.errorMessage}>{caractersRemaning + " caracteres restantes"}</p>}
      </Grid>*/
      }

      <Grid container className={classes.gridContainer} style={{marginTop: '15px', height: '2px'}}>
        <Divider classes={{ root: dividerStyle.divider }} />
      </Grid>
    </Grid>
    )
  }
};

AlertsElementRain.propTypes = {
    classes: PropTypes.object.isRequired,
    setReloadRightSide: PropTypes.func,
    setAllValues: PropTypes.func,
    allValues: PropTypes.array.isRequired,
    indexPage: PropTypes.any,
    company: PropTypes.object.isRequired,
    value: PropTypes.any,
    rightSideTabs: PropTypes.number.isRequired,
  };
  
  export default withStyles(styles)(AlertsElementRain);
import { djangoApi, lambdaApi } from "services/api";
import { buffer } from '@turf/turf';
import { useAuth } from 'Context/AuthContext';


const createBuffer = (feature, bufferValue, bufferColor, index) => {
  const a = {...feature}
  const x = buffer(a, bufferValue);
  x.properties = {
    ...feature.properties,
    buffer: bufferValue.toString(),
    color: bufferColor,
    bufferCheck: 'checked',
  }
  return x
}

export const getTargetList = async () => {
  try {
    const response = await djangoApi.get('monitoring/');
    const responseCorrection = response.data.map(p => p[0]).filter(p => p.alvos)
    const plans = responseCorrection.map((plan, index) => {
        if (plan.alvos === null) return plan
        const newTargets = plan.alvos.map((target, targetIndex) => {

          const d = {
              type: 'FeatureCollection', features: [{
                  "type": "Feature",
                  "geometry": {
                    "type": target.coordenadas.type,
                    "coordinates":  target.coordenadas.coordinates
                  },
                  "properties": {
                    ...target,
                    id_plano: plan.id_plano,
                    "planId": index,
                    "targetId": targetIndex,
                    "buffer": "0"
                    
                  },
                  "id": `${target.nome}-${target.id_alvo}`
              }]
            }
            delete d.features[0].coordinates;

            var tempCustomBuffers = [];
            var tempDefaultBuffers = [{km: 4, color: "#FB1C29"}, {km:10, color: "#FDA731"}, {km:20, color: "#118014"}];
            d.features[0].properties.buffers? d.features[0].properties.buffers?.map((buff) => 
            tempCustomBuffers.push(buff)) : tempCustomBuffers;
            const buffers = d.features[0].properties.buffers_padrao? tempCustomBuffers.concat(tempDefaultBuffers) : tempDefaultBuffers;
            const newBuffers = buffers?.map((buff, index) => {
              return createBuffer(d.features[0], buff.km, buff.color, index)
            })
            d.features.push(...newBuffers);

            return {...target, coordenadas: d, id: targetIndex}
        })

        return {...plan, alvos: newTargets, id: index }
    }).sort(function (a, b) {
      return ('' + a.empresa).localeCompare(b.empresa);
  })

    const companies = plans.reduce((arr, plan)=> {
      if (!arr.length || arr[arr.length-1].nome !== plan.empresa){
        arr.push({nome: plan.empresa, id_empresa_id: plan.id_empresa_id, planos: [plan]})
      }else{
        arr[arr.length-1].planos.push(plan);
      }
      return arr
    }, []).map((company, companyIndex) => {
      const empresa = {...company}
      const planos = company.planos.map((plan, planIndex) => ({...plan, id: planIndex}))
      empresa.planos = planos;
      empresa.id = companyIndex;
      return empresa;
    })
    return companies
  } catch (error) {
    throw new Error('Não foi possível obter as informações sobre os alvos.');
  }
};

export const getClientTargetList = async () => {
  try {
    const response = await djangoApi.get('monitoring/');
    const responseCorrection = response.data.map(p => p[0]).filter(p => p.alvos)
    const plans = responseCorrection.map((plan, index) => {
        if (plan.alvos === null) return plan
        const newTargets = plan.alvos.map((target, targetIndex) => {

          const d = {
              type: 'FeatureCollection', features: [{
                  "type": "Feature",
                  "geometry": {
                    "type": target.coordenadas.type,
                    "coordinates":  target.coordenadas.coordinates
                  },
                  "properties": {
                    ...target,
                    id_plano: plan.id_plano,
                    "planId": index,
                    "targetId": targetIndex,
                    "buffer": "0"
                    
                  },
                  "id": `${target.nome}-${target.id_alvo}`
              }]
            }
            delete d.features[0].coordinates;

            var tempCustomBuffers = [];
            var tempDefaultBuffers = [{km: 4, color: "#FB1C29"}, {km:10, color: "#FDA731"}, {km:20, color: "#118014"}];
            d.features[0].properties.buffers? d.features[0].properties.buffers?.map((buff) => 
            tempCustomBuffers.push(buff)) : tempCustomBuffers;
            const buffers = d.features[0].properties.buffers_padrao? tempCustomBuffers.concat(tempDefaultBuffers) : tempDefaultBuffers;
            const newBuffers = buffers?.map((buff, index) => {
              return createBuffer(d.features[0], buff.km, buff.color, index)
            })
            d.features.push(...newBuffers);

            return {...target, coordenadas: d, id: targetIndex}
        })

        return {...plan, alvos: newTargets, id: index }
    }).sort(function (a, b) {
      return ('' + a.empresa).localeCompare(b.empresa);
  })

    const companies = plans.reduce((arr, plan)=> {
      if (!arr.length || arr[arr.length-1].nome !== plan.empresa){
        arr.push({nome: plan.empresa, id_empresa_id: plan.id_empresa_id, planos: [plan]})
      }else{
        arr[arr.length-1].planos.push(plan);
      }
      return arr
    }, []).map((company, companyIndex) => {
      const empresa = {...company}
      const planos = company.planos.map((plan, planIndex) => ({...plan, id: planIndex}))
      empresa.planos = planos;
      empresa.id = companyIndex;
      return empresa;
    })

    return getUserAuthenticated(companies)
  } catch (error) {
    throw new Error('Não foi possível obter as informações sobre os alvos.');
  }
};

const getUserAuthenticated = async (companies) => {
  const response = await djangoApi.get(`usuario/get/`)
  return getClientTargets(response, companies)
}

const getClientTargets = async (user, companies) => {
  if(user.data.id_usuario_empresa){
    const response = await djangoApi.get(`usuariosempresa_infos/?id_usuario=${user.data.id_usuario_empresa}`)
    if(response.status === 200) {
        const responseData = {
            id_empresa: response.data[0][0][0].empresa[0].id_empresa,
            planos: response.data[0][0][0].info.map((plans) => {return {id_plano: plans.plano.id_plano, alvos: plans.alvos}}),
        }
  
        const allTargetsArr = [];
        const filteredCompany = companies?.filter((company) => company?.id_empresa_id == responseData?.id_empresa);
        filteredCompany[0]?.planos.map((plano) => plano.alvos?.map((alvo) => allTargetsArr.push(alvo.id_alvo)));
        const clientTargetsArr = [];
        responseData.planos.map((plano) => plano.alvos?.map((alvo) => clientTargetsArr.push(alvo.id_alvo)));
        const intersection = allTargetsArr.filter((id_alvo) => clientTargetsArr.includes(id_alvo));
        const alvos = {
          id: 0,
          id_empresa_id: filteredCompany[0]?.id_empresa_id,
          nome: filteredCompany[0]?.nome,
          planos: []
        };
  
        filteredCompany[0].planos.map((plano1) => plano1.alvos?.map((alvo) => {
          if(intersection.includes(alvo.id_alvo)){
            if(alvos.planos.length > 0 && alvos.planos?.filter((plano2) => plano1.id_plano == plano2.id_plano).length > 0){
              alvo.id = alvos.planos.alvos?.length > 0? alvos.planos.length - 1 : 0;
              alvos.planos[alvos.planos.length - 1].alvos.push(alvo)
            }
            else{
              plano1.id = alvos.planos?.length > 0? alvos.planos.length - 1 : 0;
              alvos.planos.push(plano1);
              alvo.id = 0;
              alvos.planos[alvos.planos.length - 1].alvos = [alvo]
            }
          }
        }));
        return [alvos];
    }
  }
}

export const monitoringNotification = async () => {
  try{
    const response = await djangoApi.get('notificacoes_acao/');
    const data = [...response.data.alerta_raios_alvos_alertar]
      .filter(a => a[0].distancia < 10)
      .sort((a,b) => a.distancia - b.distancia)
      .map(a => ({...a[0], tipo: 'raio'}))

    const fourKm = data.filter(a => a.distancia < 4)
    const tenKm = data.filter(a => a.distancia >= 4)
    const releases = [...response.data.alvos_liberar].map(a => ({...a, tipo:'liberacao_raio'}))
    let rain_alert = []
    if (response.data.avisos_chuva){
      rain_alert = [...response.data.avisos_chuva].map(a => ({...a, tipo:'aviso_chuva'}))
    }
    return [...fourKm, ...releases, ...tenKm, ...rain_alert];
  }catch(e){
    throw Error('Não foi possível carregar as notificações de monitoramento')
  }
}

export const forecastNotification = async () => {
  try{
    const response = await lambdaApi.get('/boletins');
    const quinze_min_antecedencia = response.data.quinze_min_antecedencia? response.data.quinze_min_antecedencia.map((reportCard) => ({...reportCard, tipo: "quinze_min_antecedencia", closed: false})) : [];
    const trinta_min_antecedencia = response.data.trinta_min_antecedencia? response.data.trinta_min_antecedencia.map((reportCard) => ({...reportCard, tipo: "trinta_min_antecedencia", closed: false})) : [];
    const momento_envio = response.data.momento_envio? response.data.momento_envio.map((reportCard) => ({...reportCard, tipo: "momento_envio", closed: false})) : [];
    const quinze_min_atraso = response.data.quinze_min_atraso? response.data.quinze_min_atraso.map((reportCard) => ({...reportCard, tipo: "quinze_min_atraso", closed: false})) : [];
    const trinta_min_atraso = response.data.trinta_min_atraso? response.data.trinta_min_atraso.map((reportCard) => ({...reportCard, tipo: "trinta_min_atraso", closed: false})) : [];

    return [...quinze_min_antecedencia, ...trinta_min_antecedencia, ...momento_envio, ...quinze_min_atraso, ...trinta_min_atraso];
  }catch{
    throw Error('Não foi possível carregar as notificações de previsão')
  }
}

export const getRainAlertList = async () => {
  try{
    const response = await djangoApi.get('avisoschuva/vigente/');
    return response.data;
  }catch{
    throw Error(' nao foi possivel obter os avisos de chuva')
  }
}
import styled from 'styled-components';
import { colors } from '../../../styled';
import Divider from '@material-ui/core/Divider';

export const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-family:  'Segoe UI', 'Roboto', 'Open Sans', sans-serif;
`

export const PlansTabStyled = styled(Base)`
  gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: transparent;
  margin-bottom: 40px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.dark};
      border: 1px solid ${colors.dark};
    }
  }
`

export const MapStyles = styled(Base)`
  gap: 0;
`

export const DividerStyled = styled(Divider)`
  width: 100%;
  height: 1px;
  background-color: #848484;
`

export const SectionTitle = styled.h2`
  color: ${colors.blue.dark};
  align-self: flex-start;
  font-weight: 700;
  font-family:  'Segoe UI', 'Roboto', 'Open Sans', sans-serif;
`

export const MapStyleOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const MapStyleOption = styled.div`
  position: relative;
  width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  input{
    position: absolute;
    top: -9999px;

    &:checked + label {
      background-color: ${colors.selected};
    }
    &:checked:hover + label{
      background-color: ${colors.selected};
    }
  }

  label {
    padding: 8px;
    border-radius: 999px;
    background-color: ${colors.blue.medium};
    text-align: center;
    transition: background-color 0.3s ease;
    color: ${colors.white};

    &:hover{
      background-color: ${colors.selected};
    }
  }
`

export const SearchDiv = styled.div`
  margin-top: 20px;
  display: flex;
  width: 90%;
  justify-content: space-between;
  gap: 16px;
`

export const SearchButton = styled.button`
  font-size: 24px;
  height: 100%;
  height: 52px;
  width: 52px;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 13px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  height: 100%;
`

export const PlanItem = styled.div`
  width: calc(100% - ${props => props.ml ? props.ml : '0px'});
  display: flex;
  background-color: transparent;
  align-items: center;
  gap: 8px;
  color: ${(props) => (props.checked ? colors.selected : colors.dark)};
  font-family: 'Segoe UI';
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-overflow: ellipsis;
  /* margin-right: 12px; */
  margin-left: ${props => props.ml ? props.ml : '0px'};
  &:hover{
    color: #42369A;
  }
  & > .MuiCheckbox-root{
    color: ${props => props.checked ? colors.selected : colors.dark};
  }
  h2 {
    flex: 1;
    margin: 0;
    font-size: 20px;
  }
  ${ItemContent} > svg {
    transform: ${props => props.isOpen ? 'rotateZ(0)' : 'rotateZ(-90deg)'};
    transition: 0.3s;
  }
`

export const CompanyItem = styled(PlanItem)`
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
`

export const Span = styled.span`
  display: flex;
  width: calc(220px - ${props => props.ml ? props.ml : '0px'});
  flex-grow: 1;
  flex-shrink: 1;
  word-wrap: break-word;
  align-items: center;
`

export const TargetList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* & > div > ${PlanItem}{
    background-color: black;
  } */

`

export const TargetItem = styled.div`
  width: calc(100% - ${props => props.ml ? props.ml : '0px'});
  display: flex;
  align-items: center;
  font-family: 'Segoe UI';
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.checked ? colors.selected : colors.dark)};
  margin-left: ${props => props.ml ? props.ml : '0px'};
  & > .MuiCheckbox-root{
    color: ${(props) => (props.checked ? colors.selected : colors.dark)};
  }
`



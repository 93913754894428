import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
// Libs
import { bbox } from '@turf/turf';

// Components
import {
  DividerStyled,
  PlansTabStyled,
  SearchDiv,
  SectionTitle,
  PlanItem,
  TargetList,
  ItemContent,
  CompanyItem,
  Span,
  SearchButton
} from './styled';
import {Checkbox} from '@material-ui/core';
import {Column, Row} from 'components/Flex';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Collapsible from 'components/Collapsible';
import TargetInfo from '../TargetInfo';
import useLoad from 'hooks/useLoad';
import useInterval from 'hooks/useInterval';

// Icons
import {MdExpandMore} from 'react-icons/md';

// Hooks
import {useTarget} from 'Context/TargetContext';
import {useMapContext} from 'Context/MapContext';
import useToggle from 'hooks/useToggle';
import {useTargetState} from 'Context/TargetStateContext';
import {useTargetCustomAlertState} from 'Context/TargetCustomAlertStateContext';
import { getTargetList, getRainAlertList } from 'services/mapsPortal/initialLoad';


const PlansTab = (props) => {
  const { toggleHotkeys} =
      useMapContext();
  const planRef = useRef(null);
  const { setPlanTabWidth } = props;
  const {
    planos,
    toShow: checkboxes
  } = useTarget();
  const [ data, setData ] = useState(planos);
  const [ reloadData, setReloadData ] = useState(false); 
  const { targetState } = useTargetState();
  const { targetCustomAlertState } = useTargetCustomAlertState();
  const [ searchValue, setSearchValue ] = useState('');

  const [ sortAscending, setSortAscending ] = useState(true);

  const [collapse, setCollapse] = useState([]);
  const [collapseAlerts, setCollapseAlerts] = useState([]);
  const [showCompanies, toggleShowCompanies] = useToggle(false);
  const [showInAlert, toggleShowInAlert] = useToggle(false);
  const [showInRainAlert, toggleShowInRainAlert] = useToggle(false);
  const [inRainAlert, setInRainAlert] = useState([]);
  const [showInCustomAlert, toggleShowInCustomAlert] = useToggle(false);

  const [organizedShowInterface, setOrganizedShowInterface] = useState([]);
  const [isRain, setIsRain] = useState(false);
  
  const organizedInterface = [];

  const callback = useCallback(() => ({}), [])
  useLoad(callback, {});

  useEffect(() => {
    const p = () => data.map(company => ({
      value: false,
      plans: company.planos.map(plans => false)
    }))
    setCollapse(p())
    setCollapseAlerts(p())
  }, [JSON.stringify(data)])

  const collapseAlertCompany = (id) => {
    setCollapseAlerts(p => {
      const a = [...p];
      a[id].value = !a[id].value;
      return a;
    })
  }

  const collapseAlertPlan = (cId, pId) => {
    setCollapseAlerts(p => {
      const a = [...p];
      a[cId].plans[pId] = !a[cId].plans[pId]
      return a;
    })
  }


  const collapseCompany = (id) => {
    setCollapse(p => {
      const a = [...p];
      a[id].value = !a[id].value;
      return a;
    })
  }

  const collapsePlan = (cId, pId) => {
    setCollapse(p => {
      const a = [...p];
      a[cId].plans[pId] = !a[cId].plans[pId]
      return a;
    })
  }

  const getRain = async () => {
    
    if(!organizedInterface.some(item => item.key === "empresaInterface")){
      organizedInterface.push(<EmpresaInterface name="EmpresaInterface" key="empresaInterface"/>);
    }

    try {
      const respRain = await getRainAlertList();

      if(respRain.length > 0){
        const empresaIndex = organizedInterface.findIndex(item => item.key === "empresaInterface");
        
        if (empresaIndex !== -1 && !organizedInterface.some(item => item.key === "rainInterface")) {
          organizedInterface.splice(empresaIndex, 0, <RainInterface name="RainInterface" key="rainInterface"/>);
        }

        setIsRain(true);

      } else {
        const empresaIndex = organizedInterface.findIndex(item => item.key === "empresaInterface");

        if (empresaIndex !== -1 && !organizedInterface.some(item => item.key === "rainInterface")) {
          organizedInterface.splice(empresaIndex + 2, 0, <RainInterface name="RainInterface" key="rainInterface"/>);
        }

      }
      
      setInRainAlert(respRain);
    } catch (error) {
      console.log('Erro ao buscar alerta de chuva: ', error);
    }
  };



  const handleFilter = (text) => {
    return text
      .toLowerCase()
      .includes(searchValue.toLowerCase())
  }

  const plansLinesToShow = data.filter(empresa => {
    const a = empresa.planos.filter(plan => {
        const targets = plan.alvos.filter(target => handleFilter(target.nome))
        return targets.length || handleFilter(plan.nome)
    })
    return a.length || handleFilter(empresa.nome)
  });
  
  const filterPlanInAlert = (plan) => {
    return plan.alvos.some(target => targetState[target.id_alvo]);
  }

  const filterinRainAlert = (plan) => {
    return plan.planos.some((subPlan) => {
      return subPlan.alvos.some((target) => {
        const targetMatch =  inRainAlert && inRainAlert.some(alert => alert.id_alvo === target.id_alvo);
        return targetMatch;
      });
    });
  };
 
  const filterPlanInAlertCustomAlert = (plan) => {
    return plan.alvos.some(target => targetCustomAlertState[target.id_alvo]);
  }

  const filterCompanyInAlert = (company) => {
    return company.planos.some(plan => filterPlanInAlert(plan))
  }

  const filterCompanyInCustomAlert = (company) => {
    return company.planos.some(plan => filterPlanInAlertCustomAlert(plan))
  }

  const reloadedData = async () => {
    await getTargetList().then((response) => {
      if(response?.length > 0){
        setData(response)
      }
    })
    setReloadData(false);
  }

  useEffect(() => {
    if(reloadData){
      reloadedData()
    }
  }, [reloadData])


  const loadAndReloadInterfaces = async () => {

    await getRain();

    // Função para verificar se algum alvo existe no alerta de raio
    const checkIfAnyTargetExists = () => {
      return plansLinesToShow.some(company => 
        company.planos.some(plan =>
          plan.alvos?.some(target => 
            targetState[target.id_alvo] && (handleFilter(target.nome) || handleFilter(plan.nome))
          )
        )
      );
    };
  
    const resultRaio = checkIfAnyTargetExists();
  
    // Função para verificar se algum alerta personalizado existe
    const checkIfPersonalizedExists = () => {
      return plansLinesToShow.some(company =>
        filterCompanyInCustomAlert(company) &&
        company.planos.some(plan =>
          filterPlanInAlertCustomAlert(plan) &&
          plan.alvos?.some(target =>
            targetState[target.id_alvo] && (handleFilter(target.nome) || handleFilter(plan.nome))
          )
        )
      );
    };
  
    const resultPerso = checkIfPersonalizedExists();
  

    if (resultRaio === true) {
      const empresaIndex = organizedInterface.findIndex(item => item.key === "empresaInterface");

      if (empresaIndex !== -1 && !organizedInterface.some(item => item.key === "alertInterface")) {
        organizedInterface.splice(empresaIndex, 0, <AlertInterface name="AlertInterface" key="alertInterface" />);
      }

    } else {
      const empresaIndex = organizedInterface.findIndex(item => item.key === "empresaInterface");
      if (empresaIndex !== -1 && !organizedInterface.some(item => item.key === "alertInterface")) {
        organizedInterface.splice(empresaIndex + 1, 0, <AlertInterface name="AlertInterface" key="alertInterface" />);
      }
    }

    if (resultPerso === true) {
      const empresaIndex = organizedInterface.findIndex(item => item.key === "empresaInterface");

      if (empresaIndex !== -1 && !organizedInterface.some(item => item.key === "personalizedInterface")) {
        organizedInterface.splice(empresaIndex, 0, <PersonalizedInterface name="PersonalizedAlertInterface" key="personalizedInterface" />);
      }
    } else {

      const empresaIndex = organizedInterface.findIndex(item => item.key === "empresaInterface");

      if (empresaIndex !== -1 && !organizedInterface.some(item => item.key === "personalizedInterface")) {
        organizedInterface.splice(empresaIndex + 1, 0, <PersonalizedInterface name="PersonalizedAlertInterface" key="personalizedInterface" />);
      }
    }
  
    setOrganizedShowInterface(organizedInterface);
  }

  useEffect(() => {
    getRain();
    loadAndReloadInterfaces();
  }, []);
  

  useInterval(() => {
    getRain();
    loadAndReloadInterfaces();
  }, 15000);

  useEffect(() => {
    if(planRef){
      setPlanTabWidth(planRef?.current? planRef.current.getBoundingClientRect().width : null);
    }
  }, [planRef])


  const AlertInterface = () => (
    <Collapsible isOpen={showInCustomAlert} main={(
      <>
        <CompanyItem isOpen={showInCustomAlert}>
          <Checkbox
            label={'Todos'}
            onChange={checkboxes.toggleAll}
            checked={checkboxes.getAll()}
            style={{color: "#1B1B1B"}}
          />
          <ItemContent onClick={toggleShowInCustomAlert}>
            <h2>EM ALERTA</h2>
            <MdExpandMore style={{fontSize: '32px'}}/>
          </ItemContent>
        </CompanyItem>
        <DividerStyled />
      </>
    )}>
      {plansLinesToShow
        .sort((a, b) => sortAscending
          ? a.nome.localeCompare(b.nome)
          : b.nome.localeCompare(a.nome))
        .filter((company) => filterCompanyInAlert(company))
        .map((company) => (
          <Collapsible
            key={`sidebarempresaAlert-${company.id}`}
            isOpen={collapseAlerts.length ? collapseAlerts[company.id].value : false}
            main={(
              <CompanyItem
                key={company.id + '-sidebarEmpresa-alert'}
                checked={true}
                isOpen={collapseAlerts.length ? collapseAlerts[company.id].value : false}
              >
                <Checkbox
                  label={company.nome}
                  onChange={() => checkboxes.toggle(company.id)}
                  checked={checkboxes.get(company.id)}
                  style={{color: "#1B1B1B"}}
                />
                <ItemContent onClick={() => collapseAlertCompany(company.id)}>
                  <Span>{company.nome}</Span>
                  <MdExpandMore style={{fontSize: '32px'}}/>
                </ItemContent>
              </CompanyItem>
            )}
          >
            {company.planos
              .filter(plan => filterPlanInAlert(plan))
              .map((plan) => (
                <Collapsible
                  key={`sidebarplanoAlert-${plan.id}`}
                  isOpen={collapseAlerts.length ? collapseAlerts[company.id].plans[plan.id] : false}
                  main={(
                    <PlanItem
                      ml='16px'
                      checked={true}
                      // isOpen={true}
                      isOpen={collapseAlerts.length ? collapseAlerts[company.id].plans[plan.id] : false}
                    >
                      <Checkbox
                        label={plan.nome}
                        onChange={() => checkboxes.toggle(company.id, plan.id)}
                        checked={checkboxes.get(company.id, plan.id)}
                        style={{color: "#1B1B1B"}}
                      />
                      <ItemContent onClick={() => collapseAlertPlan(company.id, plan.id)}>
                        <Span ml='16px'>{plan.nome}</Span>
                        <MdExpandMore style={{fontSize: '32px'}}/>
                      </ItemContent>
                    </PlanItem>
                  )}
                >
                  {plan.alvos
                    ?.filter(
                      target => {targetState[target.id_alvo] && (handleFilter(target.nome) || handleFilter(plan.nome))
                      //  setExistsRaio(true)
                      })
                    .sort((a, b) => sortAscending
                      ? a.nome.localeCompare(b.nome)
                      : b.nome.localeCompare(a.nome))
                    .map((target) => (
                    <Column key={`sidebarAlvoAlert-${plan.id}-${target.id}`}>
                      <TargetInfo
                        target={target}
                        plan={plan}
                        company={company}
                        checkboxes={checkboxes}
                        checked={true} // Adjust as needed
                      />
                    </Column>
                  ))}
                </Collapsible>
              ))}
          </Collapsible>
        ))}
    </Collapsible>
  );

  const EmpresaInterface = () => (
    <Collapsible isOpen={showCompanies} main={(
      <>
        <CompanyItem isOpen={showCompanies}>
          <Checkbox
            label={'Todos'}
            onChange={checkboxes.toggleAll}
            checked={checkboxes.getAll()}
            style={{color: "#1B1B1B"}}
          />
          <ItemContent onClick={toggleShowCompanies}>
            <h2>EMPRESAS</h2>
            <MdExpandMore style={{fontSize: '32px'}}/>
          </ItemContent>
        </CompanyItem>
        <DividerStyled />
      </>
    )}>
    {plansLinesToShow.sort((a, b) => sortAscending
    ? a.nome.localeCompare(b.nome)
    : b.nome.localeCompare(a.nome))
    .map((company) => {
    return(
    <Collapsible
      key={`sidebarempresa-${company.id}`}
      isOpen={collapse.length ? collapse[company.id].value : false}
      main={(
        <CompanyItem
          key={company.id + '-sidebarEmpresa-check'}
          // isOpen={true}
          checked={filterCompanyInAlert(company)}
          isOpen={collapse.length ? collapse[company.id].value : false}
        >
          <Checkbox
            label={company.nome}
            onChange={() => checkboxes.toggle(company.id)}
            checked={checkboxes.get(company.id)}
            style={{color: "#1B1B1B"}}
          />
          <ItemContent onClick={() => collapseCompany(company.id)}>
            <Span>
                {company.nome}
            </Span>
            <MdExpandMore style={{fontSize: '32px'}}/>
          </ItemContent>
        </CompanyItem>
      )}
    >
      {company.planos
        .filter(plan => {
            const targets = plan.alvos.filter(target => handleFilter(target.nome))
            return targets.length || handleFilter(plan.nome)
        })
        .map((plan) => (
          <Collapsible
            key={`sidebarplano-${plan.id}`}
            // isOpen={true}
            isOpen={collapse.length ?collapse[company.id].plans[plan.id] : false}
            main={(
              <PlanItem
                ml='16px'
                key={plan.id + '-sidebarPlano-check'}
                // isOpen={true}
                checked={filterPlanInAlert(plan)}
                isOpen={collapse.length ? collapse[company.id].plans[plan.id] : false}
              >
                <Checkbox
                  label={plan.nome}
                  onChange={() => checkboxes.toggle(company.id, plan.id)}
                  checked={checkboxes.get(company.id, plan.id)}
                  style={{color: "#1B1B1B"}}
                />
                <ItemContent onClick={() => collapsePlan(company.id, plan.id)}>
                  <Span ml='16px'>
                      {plan.nome}
                  </Span>
                  <MdExpandMore style={{fontSize: '32px'}}/>
                </ItemContent>
              </PlanItem>
            )}
          >
            {plan.alvos
              ?.filter(
                target => handleFilter(target.nome) || handleFilter(plan.nome)
              )
              .sort((a, b) => sortAscending
                ? a.nome.localeCompare(b.nome)
                : b.nome.localeCompare(a.nome))
              .map((target) => (
              <Column key={`sidebarAlvo-${plan.id}-${target.id}`}>
                <TargetInfo
                  target={target}
                  plan={plan}
                  company={company}
                  checkboxes={checkboxes}
                  checked={targetState[target.id_alvo]} //Need Change
                  setReloadData={setReloadData}
                />
              </Column>
            ))}
          </Collapsible>
        ))}
      </Collapsible>
    )})}
  </Collapsible>
  );

  const PersonalizedInterface = () => (
    <Collapsible isOpen={showInAlert} main={(
      <>
        <CompanyItem isOpen={showInAlert}>
          <Checkbox
            label={'Todos'}
            onChange={checkboxes.toggleAll}
            checked={checkboxes.getAll()}
            style={{color: "#1B1B1B"}}
          />
          <ItemContent onClick={toggleShowInAlert}>
            <h2>EM ALERTA PERSONALIZADO</h2>
            <MdExpandMore style={{fontSize: '32px'}}/>
          </ItemContent>
        </CompanyItem>
        <DividerStyled />
      </>
    )}>
    {plansLinesToShow.sort((a, b) => sortAscending
      ? a.nome.localeCompare(b.nome)
      : b.nome.localeCompare(a.nome))
    .filter((company) => filterCompanyInCustomAlert(company))
    .map((company) => {
      return(
        <Collapsible
          key={`sidebarempresaAlert-${company.id}`}
          isOpen={collapseAlerts.length ? collapseAlerts[company.id].value : false}
          main={(
            <CompanyItem
              key={company.id + '-sidebarEmpresa-alert'}
              // isOpen={true}
              checked={true} //ALTERAR LINHA COM STATUS DO ALVO: EM ALERTA = TRUE
              isOpen={collapseAlerts.length ? collapseAlerts[company.id].value : false}
            >
              <Checkbox
                label={company.nome}
                onChange={() => checkboxes.toggle(company.id)}
                checked={checkboxes.get(company.id)}
                style={{color: "#1B1B1B"}}
              />
              <ItemContent onClick={() => collapseAlertCompany(company.id)}>
                <Span>
                    {company.nome}
                </Span>
                <MdExpandMore style={{fontSize: '32px'}}/>
              </ItemContent>
            </CompanyItem>
          )}
        >
          {company.planos
            .filter(plan => filterPlanInAlertCustomAlert(plan))
            .filter(plan => {
                const targets = plan.alvos.filter(target => handleFilter(target.nome))
                return targets.length || handleFilter(plan.nome)
            })
            .map((plan) => (
              <Collapsible
                key={`sidebarplanoAlert-${plan.id}`}
                // isOpen={true}
                isOpen={collapseAlerts.length ?collapseAlerts[company.id].plans[plan.id] : false}
                main={(
                  <PlanItem
                    ml='16px'
                    key={plan.id + '-sidebarPlanoAlert-check'}
                    checked={true}
                    // isOpen={true}
                    isOpen={collapseAlerts.length ? collapseAlerts[company.id].plans[plan.id] : false}
                  >
                    <Checkbox
                      label={plan.nome}
                      onChange={() => checkboxes.toggle(company.id, plan.id)}
                      checked={checkboxes.get(company.id, plan.id)}
                      style={{color: "#1B1B1B"}}
                    />
                    <ItemContent onClick={() => collapseAlertPlan(company.id, plan.id)}>
                      <Span ml='16px'>
                          {plan.nome}
                      </Span>
                      <MdExpandMore style={{fontSize: '32px'}}/>
                    </ItemContent>
                  </PlanItem>
                )}
              >
                {plan.alvos
                  ?.filter(
                    target => {targetState[target.id_alvo] && (handleFilter(target.nome) || handleFilter(plan.nome)) 
                      // setExistsPerso(true);
                    })
                  .sort((a, b) => sortAscending
                    ? a.nome.localeCompare(b.nome)
                    : b.nome.localeCompare(a.nome))
                  .map((target) => (
                  <Column key={`sidebarAlvoAlert-${plan.id}-${target.id}`}>
                    <TargetInfo
                      target={target}
                      plan={plan}
                      company={company}
                      checkboxes={checkboxes}
                      checked={true} //Need Change
                    />
                  </Column>
                ))}
              </Collapsible>
            ))}
        </Collapsible>
    )})}
  </Collapsible>
  );

  const RainInterface = () => (
    
    <Collapsible isOpen={showInRainAlert} main={(
      <>
        <CompanyItem isOpen={showInRainAlert}>
          <Checkbox
            label={'Todos'}
            onChange={checkboxes.toggleAll}
            checked={checkboxes.getAll()}
            style={{color: "#1B1B1B"}}
          />
          <ItemContent onClick={toggleShowInRainAlert}>
            <h2>EM AVISO DE CHUVA</h2>
            <MdExpandMore style={{fontSize: '32px'}}/>
          </ItemContent>
        </CompanyItem>
        <DividerStyled />
      </>  
    )}>
    { plansLinesToShow.sort((a, b) => sortAscending
    ? a.nome.localeCompare(b.nome)
    : b.nome.localeCompare(a.nome)).filter((company) => filterinRainAlert(company)).map((company)=>{
      return(
        <Collapsible
          key={`sidebarempresaAlert-${company.id}`}
          isOpen={collapseAlerts.length ? collapseAlerts[company.id].value: false}
          main={(
            <CompanyItem
              key={company.id + '-sidebarEmpresa-alert'}
              // isOpen={true}
              checked={true} //ALTERAR LINHA COM STATUS DO ALVO: EM ALERTA = TRUE
              isOpen={collapseAlerts.length ? collapseAlerts[company.id].value : false}
            >
              <Checkbox
                label={company.nome}
                onChange={() => checkboxes.toggle(company.id)}
                checked={checkboxes.get(company.id)}
                style={{color: "#1B1B1B"}}
              />
              <ItemContent onClick={() => collapseAlertCompany(company.id)}>
                <Span>
                    {company.nome}
                </Span>
                <MdExpandMore style={{fontSize: '32px'}}/>
              </ItemContent>
            </CompanyItem>
          )}
        >
          {company.planos
          .filter(plan => {
              const targets = plan.alvos.filter(target => handleFilter(target.nome))
              return targets.length || handleFilter(plan.nome)
            })
            .map((plan) => (
              <Collapsible
                key={`sidebarplanoAlert-${plan.id}`}
                // isOpen={true}
                isOpen={collapseAlerts.length ? collapseAlerts[company.id].plans[plan.id] : false}
                main={(
                  <PlanItem
                    ml='16px'
                    key={plan.id + '-sidebarPlanoAlert-check'}
                    checked={true}
                    // isOpen={true}
                    isOpen={collapseAlerts.length ? collapseAlerts[company.id].plans[plan.id] : false}
                  >
                    <Checkbox
                      label={plan.nome}
                      onChange={() => checkboxes.toggle(company.id, plan.id)}
                      checked={checkboxes.get(company.id, plan.id)}
                      style={{color: "#1B1B1B"}}
                    />
                    <ItemContent onClick={() => collapseAlertPlan(company.id, plan.id)}>
                      <Span ml='16px'>
                          {plan.nome}
                      </Span>
                      <MdExpandMore style={{fontSize: '32px'}}/>
                    </ItemContent>
                  </PlanItem>
                )}
              >
                {plan.alvos
                  ?.filter((target) => {
                    const isInRainAlert = inRainAlert.some(alert => alert.id_alvo === target.id_alvo);
                    
                    return isInRainAlert && (handleFilter(target.nome) || handleFilter(plan.nome));
                  })
                  .sort((a, b) => 
                    sortAscending ? a.nome.localeCompare(b.nome) : b.nome.localeCompare(a.nome)
                  )
                  .map((target) => (
                    <Column key={`sidebarAlvoRain-${plan.id}-${target.id}`}>
                      <TargetInfo
                        target={target}
                        plan={plan}
                        company={company}
                        checkboxes={checkboxes}
                        checked={targetState[target.id_alvo]}
                      />
                    </Column>
                  ))
                }
              </Collapsible>
            ))}
        </Collapsible>
    )})}
  </Collapsible>
  );



  const renderInterface = (name) => {
    switch(name) {
      case "AlertInterface":
        return <AlertInterface key="alertInterface" />;
      case "EmpresaInterface":
        return <EmpresaInterface key="empresaInterface" />;
      case "PersonalizedAlertInterface":
        return <PersonalizedInterface key="personalizedInterface" />;
      case "RainInterface":
        return <RainInterface key="rainInterface" />;
      default:
        return null;
    }
  };
  


  return (
      <PlansTabStyled ref={planRef}>
          <SearchDiv>
            <Input
              style={{
                border: '1px solid #032A4A',
                paddingLeft: '6px',
                backgroundColor: 'white',
                flex: 1
              }}
              id="searchTargets"
              startAdornment={(
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              )}
              placeholder='Buscar'
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onFocus={() => toggleHotkeys(false)}
              onBlur={() => toggleHotkeys(true)}
            />
            <SearchButton onClick={()=> setSortAscending(p => !p)}>
              <Icon>import_export</Icon>
            </SearchButton>
          </SearchDiv>
        <DividerStyled />
        <TargetList>
          {!plansLinesToShow.length
            ? <SectionTitle style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>Informação não encontrada</SectionTitle>
            : (
            <>
              
              {organizedShowInterface.map((name) => renderInterface(name.props.name))}

            </>
            )}
        </TargetList>
      </PlansTabStyled>
  );
};

PlansTab.propTypes = {
  setPlanTabWidth: PropTypes.func.isRequired,
};

export default PlansTab;